<template>
  <div class="home">
    <div class="title">
      <img src="../assets/home-title.png" alt="">
    </div>
    <div class="btn" @click="toNext">
      <img src="../assets/home-btn.png" alt="">
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { useRouter } from 'vue-router'
export default {
  name: 'HomeView',
  // components: {
  //   HelloWorld
  // }
  setup() {
    const router = useRouter()
    const toNext =() => {
      router.push({
        path:'/fullview',
      })
    }
    return {
      toNext
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: url(../assets/homebg.png) no-repeat center center;
  background-size: cover;
  .title {
    width: 150px;
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn {
    width: 165px;
    position: absolute;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);
  }
}

</style>
