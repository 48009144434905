<template>
  <router-view/>
</template>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height:100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
img {
  width: 100%;
}
</style>
