import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/fullview',
    name: 'Fullview',
    component: () => import('../views/Fullview.vue')
  },
  {
    path: '/flower',
    name: 'Flower',
    component: () => import('../views/Flower.vue')
  },
  { // 6.顶面
    path: '/xuting',
    name: 'Xuting',
    component: () => import('../views/Xuting.vue')
  },
  { // 7.序言
    path: '/xuyan',
    name: 'Xuyan',
    component: () => import('../views/Xuyan.vue')
  },
  { // 10.千载治吏 上善若水—德政箴言
    path: '/10_zhenyan',
    name: '10_zhenyan',
    component: () => import('../views/10_zhenyan.vue')
  },
  { // 11.千载治吏 上善若水—清廉风骨、千秋传颂
    path: '/11_chuansong',
    name: '11_chuansong',
    component: () => import('../views/11_chuansong.vue')
  },
  { // 14.刑治 澄砂汰砾—肃纪严惩
    path: '/14_yancheng',
    name: '14_yancheng',
    component: () => import('../views/14_yancheng.vue')
  },
  { //15.刑治 澄砂汰砾—明察秋毫
    path: '/15_qiuhao',
    name: '15_qiuhao',
    component: () => import('../views/15_qiuhao.vue')
  },
  { //16.刑治 澄砂汰砾—包公铡包勉
    path: '/16_baomian',
    name: '16_baomian',
    component: () => import('../views/16_baomian.vue')
  },
  { //19.定远廉韵、嘉陵水清—竹丝画帘
    path: '/19_hualian',
    name: '19_hualian',
    component: () => import('../views/19_hualian.vue')
  },
  { //20.定远廉韵、嘉陵水清—剪纸
    path: '/20_jianzhi',
    name: '20_jianzhi',
    component: () => import('../views/20_jianzhi.vue')
  },
  { //21.定远廉韵、嘉陵水清—廉字造型
    path: '/21_zaoxing',
    name: '21_zaoxing',
    component: () => import('../views/21_zaoxing.vue')
  },
  { //22.定远廉韵、嘉陵水清—廉洁歌曲
    path: '/22_gequ',
    name: '22_gequ',
    component: () => import('../views/22_gequ.vue')
  },
  { //25.廉迹 水秀山明—真静书岩
    path: '/25_shuyan',
    name: '25_shuyan',
    component: () => import('../views/25_shuyan.vue')
  },
  { //26.廉迹 水秀山明—车家湾
    path: '/26_chejiawan',
    name: '26_chejiawan',
    component: () => import('../views/26_chejiawan.vue')
  },
  { //27.廉迹 水秀山明—石照县衙
    path: '/27_xianya',
    name: '27_xianya',
    component: () => import('../views/27_xianya.vue')
  },
  { //30.红岩精神、永放光芒—历史背景、时代意义
    path: '/30_yiyi',
    name: '30_yiyi',
    component: () => import('../views/30_yiyi.vue')
  },
  { //30.红岩精神、永放光芒—历史背景、时代意义  -2
    path: '/30_yiyi_2',
    name: '30_yiyi_2',
    component: () => import('../views/30_yiyi_2.vue')
  },
  { //31.红岩精神、永放光芒—战斗场景
    path: '/31_changjing',
    name: '31_changjing',
    component: () => import('../views/31_changjing.vue')
  },
  { //34.英烈 江河万古
    path: '/34_wangu',
    name: '34_wangu',
    component: () => import('../views/34_wangu.vue')
  },
  { //35.嘱托 置水之情-狱中八条
    path: '/35_batiao',
    name: '35_batiao',
    component: () => import('../views/35_batiao.vue')
  },
  { //39.正风反腐、警钟长鸣—阳光大道和阴暗小路
    path: '/39_xiaolu',
    name: '39_xiaolu',
    component: () => import('../views/39_xiaolu.vue')
  },
  { //40.正风反腐、警钟长鸣—透明人
    path: '/40_toumingren',
    name: '40_toumingren',
    component: () => import('../views/40_toumingren.vue')
  },
  { //42.正风反腐、警钟长鸣—“连”断“珠”散（
    path: '/42_san',
    name: '42_san',
    component: () => import('../views/42_san.vue')
  },
  { //43. 新增的柱子（
    path: '/43_zhuzi',
    name: '43_zhuzi',
    component: () => import('../views/43_zhuzi.vue')
  },
  { //45.贪婪、歧途—算好人生七笔账
    path: '/45_qibizhang',
    name: '45_qibizhang',
    component: () => import('../views/45_qibizhang.vue')
  },
  { //47.贪婪、歧途—悔不当初、莫伸手  左
    path: '/47_moshenshou_left',
    name: '47_moshenshou_left',
    component: () => import('../views/47_moshenshou_left.vue')
  },
  { //47.贪婪、歧途—悔不当初、莫伸手  右
    path: '/47_moshenshou_right',
    name: '47_moshenshou_right',
    component: () => import('../views/47_moshenshou_right.vue')
  },
  { //50.不忘初心、砥砺前行—廉语 谆谆教诲
    path: '/50_jiaohui',
    name: '50_jiaohui',
    component: () => import('../views/50_jiaohui.vue')
  },
  { //51.不忘初心、砥砺前行—变迁 纪检沿革
    path: '/51_yange',
    name: '51_yange',
    component: () => import('../views/51_yange.vue')
  },
  { //52. -----20-2.榜样 自洁冰清
    path: '/52_bingqing',
    name: '52_bingqing',
    component: () => import('../views/52_bingqing.vue')
  },
  { //56.红岩书吧
    path: '/56_shuba',
    name: '56_shuba',
    component: () => import('../views/56_shuba.vue')
  },
  { //57.警醒 以史为鉴
    path: '/57_jingxing',
    name: '57_jingxing',
    component: () => import('../views/57_jingxing.vue')
  },

//////////    ---------触摸-----    ////////////////

  { //榜样
    path: '/cm_bangyang',
    name: 'cm_bangyang',
    component: () => import('../views/cm_bangyang.vue')
  },
  { //村级案例查询
    path: '/cm_chaxun',
    name: 'cm_chaxun',
    component: () => import('../views/cm_chaxun.vue')
  },
  { //大腐败案例
    path: '/cm_anli',
    name: 'cm_anli',
    component: () => import('../views/cm_anli.vue')
  },
  { //廉迹-山秀水明
    path: '/cm_shuiming',
    name: 'cm_shuiming',
    component: () => import('../views/cm_shuiming.vue')
  },
  { //廉韵
    path: '/cm_lianyun',
    name: 'cm_lianyun',
    component: () => import('../views/cm_lianyun.vue')
  },
  { //清廉风骨
    path: '/cm_fenggu',
    name: 'cm_fenggu',
    component: () => import('../views/cm_fenggu.vue')
  },
  { //山水同辉
    path: '/cm_tonghui',
    name: 'cm_tonghui',
    component: () => import('../views/cm_tonghui.vue')
  },
  { //肃纪严惩
    path: '/cm_yancheng',
    name: 'cm_yancheng',
    component: () => import('../views/cm_yancheng.vue')
  },
  { //武胜英烈生平简介
    path: '/cm_jianjie',
    name: 'cm_jianjie',
    component: () => import('../views/cm_jianjie.vue')
  },



















  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
